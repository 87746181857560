<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardBody>
          <CDataTable
            :items="iysDetail"
            :fields="fields"
            :items-per-page="10"
            :active-page="1"
            :loading="loading"
            hover
            pagination
          >
            <template #createTime="{item}">
              <td>
                {{ item.createTime && dateFormat(item.createTime) }}
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import moment from 'moment'

export default {
  name: 'IYSDetail',
  data() {
    return {
      fields: [
        { key: 'createTime', label: 'Kayıt Tarihi', _style: 'font-size:12px' },
        { key: 'emailAddress', label: 'E-mail Adresi', _style: 'font-size:12px' },
        { key: 'iysCreationTime', label: 'IYS Kayıt Tarihi', _style: 'font-size:12px' },
        { key: 'iysRequestId', label: 'Requst ID', _style: 'font-size:12px' },
        { key: 'iysSubRequestId', label: 'Requst Abone ID', _style: 'font-size:12px' },
        { key: 'iysTransactionId', label: 'Transaction ID', _style: 'font-size:12px' },
        { key: 'requestStatus', label: 'Durum', _style: 'font-size:12px' },
        { key: 'iysSource', label: 'IYS Source', _style: 'font-size:12px' }
      ],
    }
  },
  computed: {
    iysDetail(){
      return this.$store.getters.iysDetail
    },
    loading(){
      return this.$store.getters.notificationLoading
    }
  },
  methods: {
    rowClicked(item) {
      this.lastItem = item
    },

    async iysDetailList() {
      await this.$store.dispatch('iysDetail_list')
    },

    dateFormat(data){
      return moment(data).format('DD.MM.YYYY hh:mm:ss')
    },
  },
  created() {
    this.iysDetailList();
  }
}
</script>
